<template>
  <v-card min-height="194">
    <v-card-title>Metas</v-card-title>
    <div class="card-meta pa-4">
      <v-row>
        <v-col cols="12" md="4" lg="4" class="d-flex flex-column align-center">
          <div class="d-flex flex-column align-center">
            <v-card-subtitle class="pa-0" style="font-size: 1.2rem">
              Novos
            </v-card-subtitle>
            <span style="font-size: 1.5rem; font-weight: 600;">
              {{ metas.novos || 0 }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="d-flex flex-column align-center">
          <div class="d-flex flex-column align-center">
            <v-card-subtitle class="pa-0" style="font-size: 1.2rem">
              Consórcio
            </v-card-subtitle>
            <span style="font-size: 1.5rem; font-weight: 600;">
              {{ metas.consorcio || 0 }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="4" class="d-flex flex-column align-center">
          <div class="d-flex flex-column align-center">
            <v-card-subtitle class="pa-0" style="font-size: 1.2rem">
              Usados
            </v-card-subtitle>
            <span style="font-size: 1.5rem; font-weight: 600;">
              {{ metas.usados || 0 }}
            </span>
          </div>
        </v-col>
      </v-row>
      <!-- <div class="meta-chart">
        <PercentChart />
      </div> -->
    </div>
  </v-card>
</template>

<script>
// import PercentChart from "@/components/shared/PercentChart";

export default {
  name: "CardMeta",

  props: {
    metas: {
      novos: {
        type: [Number, String],
        default: 0
      },
      consorcio: {
        type: [Number, String],
        default: 0
      },
      usados: {
        type: [Number, String],
        default: 0
      }
    }
  },

  components: {
    // PercentChart
  },

  watch: {
    metas(value) {
      return value;
    }
  }
};
</script>

<style lang="scss">
.card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-meta p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 700;
}

// .meta-chart {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   margin-right: 16px;
// }
</style>
